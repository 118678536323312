.el-checkbox {
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $c-grey2 !important;
  }

  .el-checkbox__input {
    .el-checkbox__inner {
      width: 18px;
      height: 18px;
      border: 1px solid $c-blue-grey1;
      border-radius: 2px;
      -webkit-transition: none;
      transition: none;
    }

    &.is-checked,
    &.is-indeterminate {
      .el-checkbox__inner {
        width: 18px;
        height: 18px;
        border-radius: 2px;
        border: none;
        background-color: $c-primary;
      }
    }

    &.is-checked {
      .el-checkbox__inner:after {
        content: "";
        position: absolute;
        top: 0px;
        left: 5px;
        width: 6px;
        height: 12px;
        border: 2px solid #fff;
        border-left: 0;
        border-top: 0;
        -webkit-transition: none;
        transition: none;
        -webkit-transform-origin: center;
        transform-origin: center;
      }
    }

    &.is-indeterminate {
      .el-checkbox__inner:before {
        content: "";
        position: absolute;
        display: block;
        background-color: #fff;
        height: 2px;
        left: 3px;
        top: 8px;
        width: 12px;
        -webkit-transform: none;
        transform: none;
        -webkit-transition: none;
        transition: none;
        -webkit-transform-origin: center;
        transform-origin: center;
      }
    }
  }
}
