@import "@/assets/constants/index.scss";

@import "@/assets/base.scss";

body {
  margin: 0;
}

.main-container {
  min-height: 100vh;
  max-height: 100vh;

  & > .el-main {
    overflow: auto;
  }

  & > .el-aside {
    overflow: hidden;
    box-shadow: 2px 0 8px 0 $c-blue-grey4;
    position: relative;
    z-index: 2;

    ul.main-menu {
      position: static;
      width: 100%;

      &.el-menu--collapse {
        & > li.el-menu-item {
          svg {
            margin-right: 0px;
          }
        }
      }

      & > li {
        margin: 8px;
        border-radius: 8px;
        padding: 0px 16px !important;
        color: $c-blue-grey1;

        & > div:not(.el-submenu__title) {
          padding: 0 !important;
          display: flex !important;
          align-items: center;
          justify-content: center;
        }

        &.el-submenu {
          .el-submenu__title {
            padding: 0px !important;
            color: $c-blue-grey1;

            &:hover {
              background-color: transparent;
            }
          }

          &.is-active {
            .el-submenu__title {
              color: $c-primary;
            }
          }

          .el-menu {
            position: absolute;
            width: 100%;
            left: 0px;
            margin-top: 8px;
            color: $c-blue-grey1;

            li {
              height: 48px;
              margin: 8px;
              padding-left: 70px !important;
              border-radius: 8px;
              color: $c-blue-grey1;

              &.is-active {
                color: $c-primary;
              }
            }
          }
        }

        svg {
          margin-right: 32px;

          g[fill][mask] {
            fill: $c-blue-grey2;
          }
        }

        &:hover {
          background-color: $c-primary2;
        }

        &.is-active {
          color: $c-primary;
          background-color: $c-primary2;

          g[fill][mask] {
            fill: $c-primary;
          }
        }

        &.bottom {
          position: absolute;
          bottom: 0;

          &:before {
            content: "";
            position: absolute;
            top: -8px;
            left: -8px;
            width: 150%;
            border-top: 1px solid $c-blue-grey4;
          }
        }
      }
    }
  }

  .el-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 0 28px;
    border-bottom: 1px solid $c-blue-grey4;

    & > * {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .header-text-place {
      height: 48px;

      h6 {
        color: $c-grey2;
        margin-left: 28px;
      }

      svg {
        cursor: pointer;
      }
    }

    .header-login-place {
      .notification-icon {
        margin-right: 48px;
      }
    }
  }

  .el-main {
    background-color: $c-grey-light;
    padding: 0;

    & > * {
      padding: 20px;
    }
  }
}

.el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  -webkit-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
}

.el-submenu > .el-submenu__title .el-submenu__icon-arrow {
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.logo-container {
  padding: 34px 76px 34px 24px;

  .logo-mini {
    display: none;
  }

  &.is-collapsed {
    padding: 52px 19px;

    .logo-full {
      display: none;
    }

    .logo-mini {
      display: block;
    }
  }
}

.el-menu {
  border: none;
}

.subject-sub-menu {
  border-radius: 8px;

  .el-menu {
    border-radius: 8px;
    box-shadow: 0 0 8px 0 $c-blue-grey3;
    background-color: white;
  }
}
