@import "@/assets/constants/index.scss";

.login-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .user-info {
    display: flex;
    font-family: $font-primary;
    flex-direction: column;
    justify-content: right;
    font-weight: $weight-semi-bold;
    font-stretch: normal;
    font-style: normal;
    text-align: right;
    margin-right: 8px;

    span.user-name {
      font-size: 16px;
      line-height: normal;
      letter-spacing: 0.5px;
      color: $c-grey2;
    }

    span.role-name {
      font-size: 10px;
      line-height: 1.6;
      letter-spacing: 1.2px;
      color: $c-blue-grey1;
    }
  }
}
