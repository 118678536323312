.form-parent {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: white;
  padding: 32px;

  h3 {
    margin-bottom: 16px;
  }

  .grey-form-card {
    margin-bottom: 32px;
    padding: 32px;
    border-radius: 8px;
    background-color: $c-grey-light;

    &.blue {
      background-color: $c-primary2;
    }
  }

  .el-form {
    flex-grow: 1;
  }

  .el-form-item {
    width: 416px;
    position: relative;

    .half-part-item {
      width: 200px;
      float: left;

      &.radio-form-item {
        height: 64px;
        padding: 20px 16px;
        border-radius: 8px;
        background-color: $c-grey-medium;
        border: none;
        color: $c-grey2;

        .el-form-item__content {
          line-height: 24px;
        }

        &:hover {
          background-color: $c-blue-grey4;
        }

        &:focus-within {
          background-color: $c-primary2;
          border: 1px solid $c-blue;
        }
      }

      &:last-child {
        margin-left: 16px;
      }
    }

    .input-dropdown {
      button {
        width: 416px;
        text-align: left;
        background-color: $c-grey-medium;
        border: none;
        color: $c-grey2;
        overflow: hidden;
        white-space: nowrap;

        span {
          float: left;
          width: 95%;
          overflow: clip;
          text-overflow: ellipsis;
          overflow-clip-margin: 5px;
        }

        &:hover {
          background-color: $c-blue-grey4;
        }

        &:focus {
          background-color: $c-grey-medium;
          border: 1px solid $c-blue;
        }

        i {
          position: absolute;
          right: 16px;
        }
      }
    }

    &.phone-code-input {
      width: 112px;
      margin-right: 16px;

      .el-input__inner {
        padding-left: 30px;
      }

      .el-input__prefix {
        .el-icon-plus:before {
          color: $c-grey2;
        }
      }
    }

    &.phone-number-input {
      width: 288px;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    .el-form-item__label {
      position: relative;
      margin-left: 8px;
      margin-bottom: 8px;
      font-size: 12px;
      color: $c-grey2;
      line-height: 1.33;
      letter-spacing: 0.4px;
      padding: 0px;

      &:before {
        position: absolute;
        right: -13px;
        color: $c-red;
      }
    }

    &:focus-within:not(.parent-item) .el-form-item__label {
      color: $c-primary;
    }

    .el-form-item__content {
      .el-input {
        .el-input__inner {
          height: 40px;
          background-color: $c-grey-medium;
          border: none;
          border-radius: 8px;

          &:hover {
            background-color: $c-blue-grey4;
          }

          &:focus {
            background-color: $c-grey-medium;
            border: 1px solid $c-blue;
          }
        }
      }

      .el-textarea {
        .el-textarea__inner {
          background-color: $c-grey-medium;
          border: none;
          border-radius: 8px;

          &:hover {
            background-color: $c-blue-grey4;
          }

          &:focus {
            background-color: $c-grey-medium;
            border: 1px solid $c-blue;
          }
        }
      }
    }

    &.is-error {
      .el-form-item__content {
        .el-input {
          .el-input__inner {
            background-color: $c-red2;
          }
        }
      }
    }

    .el-form-item__error {
      word-break: break-word;
      margin-left: 16px;
      color: $c-red;
    }
  }
}

.button-box {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% + 40px);
  height: 88px;
  box-shadow: -2px -2px 8px 0 #eceff1;
  margin: 0 -20px -20px -20px;
  padding: 24px;

  &.drawer-buttons {
    width: calc(100% + 64px);
    margin: 0 -32px -32px -32px;
    justify-content: space-between;
  }

  button {
    cursor: pointer;
    margin-left: 16px;
  }
}

.el-dialog__body {
  .button-box {
    width: auto;
    margin: 0 -50px -50px -50px;
  }
}

.form-dropdown-filter {
  width: 416px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.el-input--prefix .el-input__inner {
  padding-left: 15px;
  padding-right: 15px;
}

.el-date-editor.el-input {
  .el-input__prefix,
  .el-input__suffix {
    display: none;
  }
}

.add-star-to-field {
  position: relative;
  display: inline-block;

  &:before {
    font-size: 12px;
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
    position: absolute;
    right: -13px;
  }
}

.score-name {
  input {
    cursor: auto !important;
    color: $c-grey2 !important;

    &:hover {
      background-color: $c-grey-medium !important;
    }
  }
}
