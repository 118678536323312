.el-radio {
  width: 100%;

  .el-radio__input.is-checked + .el-radio__label {
    color: $c-grey2 !important;
  }

  .el-radio__input {
    .el-radio__inner {
      width: 20px;
      height: 20px;
      border: 2px solid $c-blue-grey1;
      border-radius: 50%;
      -webkit-transition: none;
      transition: none;
    }

    &.is-checked {
      .el-radio__inner {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid $c-primary;
        background-color: transparent;
      }
    }

    &.is-checked {
      .el-radio__inner:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 10px;
        height: 10px;
        background-color: $c-primary;
        -webkit-transition: none;
        transition: none;
        -webkit-transform-origin: center;
        transform-origin: center;
      }
    }
  }
}
