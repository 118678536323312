.list-parent {
  .search-place {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;

    button {
      white-space: nowrap;
    }
  }

  .command-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;

    .labels-place {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: $font-primary;
      font-size: 14px;

      .total-label {
        margin-right: 16px;
        color: $c-grey2;
        font-weight: $weight-semi-bold;
      }

      .selected-label {
        color: $c-primary;
      }
    }

    .quick-filters-place {
      display: flex;
      flex-direction: row;
      align-items: center;

      .quick-filters {
        font-family: $font-primary;
        color: $c-grey2;
        margin-right: 30px;
      }

      button {
        padding: 12px 12px !important;
      }
    }
  }

  .table-parent {
    background-color: white;
    border-radius: 8px;
  }

  .list-table {
    // margin-bottom: 21px;
    color: $c-grey2;
    font-size: 14px;
    letter-spacing: 0.25px;

    .header-row th {
      padding: 12px 0;
      color: $c-grey2;
      font-weight: $weight-semi-bold;
      letter-spacing: 0.1px;
    }

    .el-table__empty-text {
      color: $c-grey2;
      letter-spacing: 0.1px;
      font-size: 16px;
      line-height: 56px;
      letter-spacing: 0.5px;
    }

    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background: #fafafc;
    }

    .el-table__row {
      height: 48px;

      td {
        padding: 0;
      }

      button.el-button {
        padding: 12px 12px !important;
      }
    }
  }

  .paging-place {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 21px 24px 20px 24px;

    .paging-label {
      font-family: $font-primary;
      font-size: 12px;

      span {
        font-weight: $weight-bold;
      }
    }

    .table-paging.el-pagination {
      font-size: 14px;
      font-weight: $weight-regular;
      color: $c-grey2;

      button {
        width: 24px;
        height: 24px;
        line-height: 24px;
        min-width: 24px;
        margin: 0px 4px;
      }

      .el-pager {
        li {
          width: 24px;
          height: 24px;
          line-height: 24px;
          min-width: 24px;
          margin: 0px 4px;

          &.active {
            color: $c-primary;
            background-color: $c-primary2;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
