.el-dialog {
  border-radius: 8px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.57);

  .el-dialog__title {
    font-family: $font-primary;
    font-size: 20px;
    font-weight: $weight-semi-bold;
    color: $c-grey2;
  }

  .el-dialog__body {
    font-size: 16px;
    color: black;
  }

  &.centered-dialog {
    top: 50%;
    transform: translate(0, -50%);
  }
}

.el-loading-spinner {
  svg {
    circle {
      stroke: $c-primary !important;
    }
  }
}

.el-overlay {
  background-color: rgba(0, 0, 0, 0.64);
}
