.el-dropdown__popper {
  padding: 8px 0 !important;
  border-radius: 8px !important;
  box-shadow: 0 0 8px 0 $c-blue-grey3 !important;
  border: none !important;

  .el-dropdown-menu {
    padding: 0px;
  }

  .el-dropdown-menu__item {
    display: flex;
    align-items: center;

    font-family: $font-primary;
    font-size: 15px;
    color: $c-grey2;
    // min-height: 40px;

    &:hover {
      background-color: $c-primary2;
    }

    svg {
      margin-right: 11px;
    }

    &.delemiter-after {
      margin-bottom: 16px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 0px;
        bottom: -8px;
        width: 100%;
        height: 1px;
        background-color: $c-blue-grey4;
      }
    }
  }

  .el-popper__arrow {
    display: none;
  }
}
