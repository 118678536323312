.button {
  font-size: 14px;
  font-weight: $weight-semi-bold;
  color: $c-white;
  border-radius: 8px;
  background-color: $c-primary;
  padding: 8px 32px;
  border: none;
  line-height: 1.71;
  letter-spacing: 0.1px;
  cursor: pointer;

  &.active {
    background-color: $c-twilight-blue;
  }

  &:hover {
    background-color: $c-blue;
  }

  &:disabled {
    opacity: 0.36;
  }

  &.secondary {
    color: $c-primary;
    background-color: $c-white;
    border: solid 1px $c-primary;
  }
}

.el-button.custom-icon-button {
  border-radius: 8px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  &.placeholder {
    color: $c-blue-grey2;
  }
}
