@import "@/assets/constants/index.scss";
.need-support {
  position: absolute;
  margin-bottom: 32px;
  margin-left: 32px;

  .row {
    display: flex;
    flex-direction: row;
  }

  .question-icon {
    flex: 0 0 0%;
    padding: 0;
    margin-right: 16px;

    img {
      width: 32px;
      height: 32px;
    }
  }

  span {
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    letter-spacing: 0.1px;
    color: #424242;
  }

  a {
    display: block;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.25px;
    color: #1565c0;
  }
}
