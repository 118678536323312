.el-tabs {
  height: 32px;
  margin-bottom: 32px;

  .el-tabs__nav-wrap::after {
    display: none;
  }

  .el-tabs__active-bar {
    display: none;
  }

  .el-tabs__item {
    height: 32px;
    padding: 4px 16px !important;
    color: $c-grey2;
    background-color: $c-grey-light;
    line-height: 24px;

    &.is-active {
      color: $c-primary;
      background-color: white;

      &::before {
        position: absolute;
        color: transparent;
        width: 100%;
        left: 0;
        top: 0;
        content: "&nbsp;";
        height: 2px;
        background-color: $c-primary;
      }
    }
  }
}
