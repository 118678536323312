body {
  font-family: $font-primary;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-weight: normal;
}

h1 {
  font-size: 95px;
  font-weight: $weight-light;
}

h2 {
  font-size: 59px;
  font-weight: $weight-light;
}

h3 {
  font-size: 48px;
}

h4 {
  font-size: 34px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 20px;
  font-weight: $weight-semi-bold;
}

.subtitle-1 {
  font-size: 16px;
}

.subtitle-2 {
  font-size: 14px;
  font-weight: $weight-semi-bold;
}

.subtitle-3 {
  font-size: 16px;
  font-weight: $weight-semi-bold;
}

.small-grey {
  color: $c-blue-grey1;
  font-size: 10px;
  font-weight: $weight-semi-bold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

p {
  font-size: 16px;
}

a {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.25px;
  text-decoration: none;
  color: $c-twilight-blue;
}
