.absolute-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.left-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}

.position-relative {
  position: relative;
}
