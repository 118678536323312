* {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
