@import "@/assets/constants/index.scss";

.el-breadcrumb {
  margin-left: 28px;
  font-size: 20px;
  font-family: $font-primary;
  color: $c-grey2;
  font-weight: $weight-semi-bold;

  .el-breadcrumb__separator {
    color: $c-grey2;
  }

  .breadcrumb-gray {
    span:first-child {
      color: $c-blue-grey1;
      font-weight: normal;
    }
  }
}
