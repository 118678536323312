@import "@/assets/constants/index.scss";
.error-screen {
  text-align: center;

  svg {
    margin-bottom: 96px;
  }

  h4 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 56px;
  }
}
