.el-slider {
  .el-slider__runway {
    height: 4px;
    background-color: $c-blue-grey3;
    margin-top: 1px;
  }

  .el-slider__bar {
    height: 4px;
    background-color: $c-blue-grey3;
    margin-top: 1px;
  }

  .el-slider__button {
    width: 16px;
    height: 16px;
    border: 2px solid $c-primary;
    background-color: $c-primary;
  }
}
