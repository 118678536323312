.grey-form-card {
  .profile-header {
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
    }

    button {
      padding: 12px 12px !important;

      &.edit-button {
        margin-right: 8px;
        padding: 6px 7px !important;
      }
    }

    .el-checkbox {
      margin-right: 54px;
    }
  }

  h6 {
    margin-bottom: 5px;

    &.small-grey {
      margin-bottom: 21px;
    }
  }
  .card-parent {
    position: relative;
    margin-top: 28px;
    margin-bottom: 28px;

    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }

    .card-row {
      display: flex;
      margin-top: 12px;

      .card-label {
        display: flex;
        align-items: center;

        width: 200px;
        margin-right: 4px;
        color: $c-blue-grey1;
        font-size: 16px;
        letter-spacing: 0.15px;

        svg {
          margin-right: 8px;
        }
      }
      .card-value {
        color: $c-grey2;
        font-size: 16px;
        letter-spacing: 0.5px;
      }
    }
  }

  .card-divider {
    position: relative;
    height: 1px;
    hr {
      position: absolute;
      width: calc(100% + 64px);
      left: -32px;
      color: $c-blue-grey4;
      border: none;
      color: $c-blue-grey4;
      background-color: $c-blue-grey4;
      height: 1px;
    }
  }
}
